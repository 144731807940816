import { collection, query, where, onSnapshot } from "firebase/firestore"
import { db } from "../../firebase"
import { resetMessagesApi } from "./messageApi"

export const setupMessageSubscription = (
  authUserUid: string,
  otherUserUid: string,
) => {
  const participantKey = [authUserUid, otherUserUid].sort().join("_")
  const messagesQuery = query(
    collection(db, "messages"),
    where("participantKey", "==", participantKey),
  )

  const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
    // Whenever there's a change in the snapshot, invalidate the 'Message' tag

    resetMessagesApi()
  })

  // Return the unsubscribe function so it can be called to stop listening to changes
  return unsubscribe
}
