import React from "react"
import router from "../routing/router"
import { Menu, MenuItem, Box, Button } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "react-redux"
import { logOut } from "../lib/auth"
import { selectHasRequest } from "../redux/app/appSelectors"

const MainMenu = () => {
  const hasRequest = useSelector(selectHasRequest)
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const viewProfile = () => router.navigate("/profile/view")
  const goToHomePage = () => {
    if (hasRequest) {
      router.navigate("/main/connect")
    } else {
      router.navigate("/main/request")
    }
  }

  return (
    <Box
      style={{
        display: "flex",
        height: "60px",
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon sx={{ color: "white" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          style={{ color: theme.palette.primary.main }}
          onClick={goToHomePage}
        >
          Home
        </MenuItem>
        <MenuItem
          style={{ color: theme.palette.primary.main }}
          onClick={viewProfile}
        >
          View Profile
        </MenuItem>
        <MenuItem
          style={{ color: theme.palette.primary.main }}
          onClick={logOut}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default MainMenu
