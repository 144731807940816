import {
  DocumentReference,
  FieldValue,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore"
import { makeUserRef } from "../users/userApi"
import { AuthUser } from "../users/user"

export interface MessageData {
  message: string
  seen: boolean
}

export interface Message {
  message: string
  fromMe: boolean
  seen: boolean
  sentAt: string
}

export interface MessageRecordBase extends MessageData {
  fromUserRef: DocumentReference
  toUserRef: DocumentReference
  toUserUid: string
  fromUserUid: string
  participantKey: string
}

export interface NewMessageRecord extends MessageRecordBase {
  sentAt: FieldValue
}

export interface MessageRecord extends MessageRecordBase {
  sentAt: Timestamp
}

export const makeNewMessageRecord = (
  fromUserUid: string,
  toUserUid: string,
  message: string,
): NewMessageRecord => {
  const fromUserRef = makeUserRef(fromUserUid)
  const toUserRef = makeUserRef(toUserUid)
  const participantKey = [fromUserUid, toUserUid].sort().join("_")

  return {
    message,
    toUserUid,
    fromUserUid,
    fromUserRef,
    toUserRef,
    participantKey,
    sentAt: serverTimestamp(),
    seen: false,
  }
}

export const convertMessageRecordtoMessage = (
  authUser: AuthUser,
  messageRecord: MessageRecord,
): Message => {
  const { message, seen, sentAt, fromUserRef } = messageRecord
  const fromMe = fromUserRef.id === authUser.uid

  let sentAtStr = ""
  if (sentAt == null) {
    sentAtStr = new Date().toISOString()
  } else {
    sentAtStr = sentAt.toDate().toISOString()
  }

  return {
    message,
    fromMe,
    seen,
    sentAt: sentAtStr,
  }
}
