import React, { useEffect, useState } from "react"
import dayjs, { Dayjs } from "dayjs"
import { useTheme } from "@mui/material/styles"
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Box,
  Slide,
  Snackbar,
  Checkbox,
  FormGroup,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import useAuth from "../hooks/useAuth"
import { Navigate } from "react-router-dom"
import router from "../routing/router"
import { useGetUserQuery } from "../redux/users/userApi"
import { useUpsertRequestMutation } from "../redux/requests/requestApi"
import { LunchRequestData } from "../redux/requests/request"
import MapImage from "../images/map.png"
import streets from "../data/streets"
import cuisines from "../data/cuisines"
import specialRequests from "../data/specialRequests"
import TimeBubble from "../components/TimeBubble"
import MainLayout from "../components/MainLayout"

const RequestPage: React.FC = () => {
  const { user } = useAuth()
  const authUser = user!
  const {
    data: userProfile,
    isLoading,
    error: userAuthError,
  } = useGetUserQuery(authUser)
  const [
    upsertRequest,
    { isSuccess, isError, isLoading: isSubmitting, error: submitError },
  ] = useUpsertRequestMutation()
  const theme = useTheme()

  const currentDate: Dayjs = dayjs()
  const [hours, setHours] = useState<string[]>([])
  const [location, setLocation] = useState("")
  const [requestStep, setRequestStep] = useState(0)
  const [checkedCuisines, setCheckedCuisines] = useState<string[]>([
    "Open to Any Cuisine",
  ])
  const [checkedSpecialRequests, setCheckedSpecialRequests] = useState<
    string[]
  >([])
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const titles = [
    "Where would you like to grab lunch?",
    "When would you like to go to lunch?",
    "When would you like to go to lunch?",
    "What would you like to get for lunch?",
    "Any special requests?",
  ]

  const incrementRequestStep = () => {
    setRequestStep((prev) => (prev < 5 ? prev + 1 : prev))
  }

  const decrementRequestStep = () => {
    setRequestStep((prev) => (prev > 0 ? prev - 1 : prev))
  }

  const handleLocation = (value: string) => {
    setLocation(value)
  }

  const handleHours = (hour: string) => {
    const index = hours.indexOf(hour)
    if (index === -1) {
      setHours((prev) => [...prev, hour])
    } else {
      setHours((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
    }
  }

  const renderTimeBubbles = (times: string[]) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "90%",
        alignItems: "center",
        marginTop: 2,
      }}
    >
      {times.map((time: string) => (
        <TimeBubble
          key={time}
          text={time}
          checked={hours.includes(time)}
          onChange={() => handleHours(time)}
        />
      ))}
    </Box>
  )

  const handleCuisinesCheckboxChange = (cuisine: string) => {
    if (cuisine === "Open to Any Cuisine") {
      setCheckedCuisines(["Open to Any Cuisine"])
    } else {
      if (checkedCuisines.includes(cuisine)) {
        setCheckedCuisines(checkedCuisines.filter((item) => item !== cuisine))
      } else {
        if (checkedCuisines.includes("Open to Any Cuisine")) {
          setCheckedCuisines([cuisine])
        } else {
          setCheckedCuisines([...checkedCuisines, cuisine])
        }
      }
    }
  }

  const handleSpecialRequestsCheckboxChange = (specialRequest: string) => {
    if (checkedSpecialRequests.includes(specialRequest)) {
      setCheckedSpecialRequests(
        checkedSpecialRequests.filter((item) => item !== specialRequest),
      )
    } else {
      setCheckedSpecialRequests([...checkedSpecialRequests, specialRequest])
    }
  }

  const submitRequest = async () => {
    const requestData: LunchRequestData = {
      location,
      date: currentDate.format("YYYY-MM-DD"),
      hours,
      cuisines: checkedCuisines,
      specialRequests: checkedSpecialRequests,
    }

    await upsertRequest({ authUser, requestData })

    router.navigate("/main/connect")
  }

  useEffect(() => {
    if (isError) {
      setOpenSnackbar(true)
    }
  }, [isSuccess, isError])

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
          display: isSubmitting || isLoading ? "block" : "none",
        }}
      >
        <CircularProgress size={"3rem"} />
      </Box>

      {!isLoading && userProfile == null && <Navigate to="/profile/create" />}

      {!isLoading && userProfile != null && (
        <MainLayout isLanding={false}>
          {requestStep === 0 && (
            <Box>
              <Typography variant="h4" color="white">
                Welcome <br />
                {userProfile.displayName},
              </Typography>
              <Typography variant="body1" color="white">
                You are steps away from finding lunch buddies. Submit a request
                to see who is available.
              </Typography>
              <br />
              {userAuthError && (
                <Alert severity="error">{errorMessage(userAuthError)}</Alert>
              )}
            </Box>
          )}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            TransitionComponent={TransitionUp}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage(submitError)}
            </Alert>
          </Snackbar>
          {requestStep !== 0 && (
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={7}>
                <Typography variant="h5" color="white" fontWeight={"bold"}>
                  {titles[requestStep - 1]}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="white"
                  sx={{
                    paddingX: 4,
                    textAlign: "end",
                  }}
                >
                  {requestStep} of 4
                </Typography>
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              minWidth: "100%",
              borderTopRightRadius: 40,
              borderTopLeftRadius: 40,
              backgroundImage:
                requestStep === 0 || requestStep === 1
                  ? `url(${MapImage})`
                  : "none",
              backgroundColor: "white",
              backgroundSize: "cover",
              overflowY: "auto",
            }}
          >
            {requestStep === 0 && (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={incrementRequestStep}
                >
                  + New Request
                </Button>
              </Box>
            )}
            {requestStep >= 1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    borderBottom: "1px solid #ddd",
                    backgroundColor: "white",
                    paddingY: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 3,
                      height: "100%",
                    }}
                  >
                    <ArrowBackIosIcon
                      onClick={decrementRequestStep}
                      sx={{ cursor: "pointer" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWeight={"bold"} fontSize={"x-large"}>
                      {location}
                    </Typography>
                  </Box>
                </Box>
                {requestStep === 1 && (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "70%",
                      padding: "20px",
                      borderRadius: 2,
                      border: 3,
                      borderColor: theme.palette.primary.main,
                      backgroundColor: "white",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="street-combo-box"
                      value={location}
                      options={streets}
                      isOptionEqualToValue={(option, value) =>
                        value === "" || option === value
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          placeholder="Please enter a location *"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value !== null) {
                          handleLocation(value)
                        } else {
                          handleLocation("")
                        }
                      }}
                    />
                  </Box>
                )}
                {requestStep === 2 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      alignItems: "center",
                      overflowY: "auto",
                    }}
                  >
                    {renderTimeBubbles(["11:30 AM", "12:00 PM", "12:30 PM"])}
                    {renderTimeBubbles(["1:00 PM", "1:30 PM", "2:00 PM"])}
                    {renderTimeBubbles(["2:30 PM", "3:00 PM", "3:30 PM"])}
                  </Box>
                )}
                {requestStep === 3 && (
                  <FormGroup
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "row",
                      textAlign: "left",
                      overflowY: "auto",
                    }}
                  >
                    {cuisines.map((element, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingX: 5,
                          marginY: 1,
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <Typography>{element}</Typography>
                        <Checkbox
                          value={element}
                          checked={checkedCuisines.includes(element)}
                          onChange={() => handleCuisinesCheckboxChange(element)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#FF0366",
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </FormGroup>
                )}
                {requestStep === 4 && (
                  <FormGroup
                    sx={{
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    {specialRequests.map((element, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingX: 5,
                          marginY: 1,
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <Typography>{element}</Typography>
                        <Checkbox
                          value={element}
                          checked={checkedSpecialRequests.includes(element)}
                          onChange={() =>
                            handleSpecialRequestsCheckboxChange(element)
                          }
                          sx={{
                            "&.Mui-checked": {
                              color: "#FF0366",
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </FormGroup>
                )}
                <Box
                  sx={{
                    textAlign: "end",
                    width: "100%",
                  }}
                >
                  {requestStep < 4 && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ margin: 5 }}
                      onClick={incrementRequestStep}
                      disabled={
                        (requestStep === 1 && location === "") ||
                        (requestStep === 2 && hours.length === 0) ||
                        (requestStep === 3 && checkedCuisines.length === 0)
                      }
                    >
                      Next
                    </Button>
                  )}
                  {requestStep === 4 && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ margin: 5 }}
                      onClick={submitRequest}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </MainLayout>
      )}
    </>
  )
}

const errorMessage = (error: any) => {
  if (error) {
    let message = "An error occurred"
    if ("message" in error) {
      message += `: ${error.message}`
    }
    return message
  }
}

const TransitionUp = (props: any) => {
  return <Slide {...props} direction="up" />
}

export default RequestPage
