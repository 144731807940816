import { useState } from "react"
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage"
import useAuth from "./useAuth"
import { storage } from "../firebase"
import { FirebaseError } from "firebase/app"

const useImageStorage = () => {
  const { user } = useAuth()
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const uploadImage = async (file: File): Promise<string | null> => {
    if (!user) {
      setError("User must be logged in to upload images.")
      return null
    }

    const storageRef = ref(storage, `images/${user.uid}`)
    setUploading(true)

    try {
      if (imageUrl) {
        await deleteImage(imageUrl)
      }

      const snapshot = await uploadBytes(storageRef, file)
      const url = await getDownloadURL(snapshot.ref)
      setImageUrl(url)
      setUploading(false)
      return url
    } catch (err) {
      if (err instanceof FirebaseError) {
        console.error(
          "Firebase error during image upload:",
          err.code,
          err.message,
        )
        setError(err.message)
      } else {
        console.error("Unexpected error during image upload:", err)
        setError("An unexpected error occurred")
      }
      setUploading(false)
      return null
    }
  }

  const deleteImage = async (imageUrl: string): Promise<void> => {
    const imageRef = ref(storage, imageUrl)

    try {
      await deleteObject(imageRef)
      setImageUrl(null)
    } catch (err) {
      if (err instanceof FirebaseError) {
        console.error(
          "Firebase error during image deletion:",
          err.code,
          err.message,
        )
        setError(err.message)
      } else {
        console.error("Unexpected error during image deletion:", err)
        setError("An unexpected error occurred")
      }
    }
  }

  return {
    uploadImage,
    deleteImage,
    uploading,
    imageUrl,
    error,
  }
}

export default useImageStorage
