import React, { ReactNode } from "react"
import { Box, Typography } from "@mui/material"

type RequestInfoBoxProps = {
  text: string
  icon: ReactNode
}

const RequestInfoBox: React.FC<RequestInfoBoxProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "fit-content",
        height: "fit-content",
        backgroundColor: "#1E518A",
        borderRadius: "6px",
        padding: 2,
        margin: 1,
        color: "white",
      }}
    >
      {props.icon}
      <Typography
        fontFamily={"sans-serif"}
        style={{
          paddingLeft: "10px",
          whiteSpace: "nowrap",
        }}
      >
        {props.text}
      </Typography>
    </Box>
  )
}

export default RequestInfoBox
