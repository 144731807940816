import React from "react"
import { Box, Typography } from "@mui/material"

type CheckBoxProps = {
  text: string
  checked: boolean
  onChange: () => void
}

const TimeBubble: React.FC<CheckBoxProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "fit-content",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: props.checked ? "#FF0366" : "#FFC000",
          borderRadius: "10px",
          cursor: "pointer",
        }}
        onClick={props.onChange}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontSize={"large"}
          style={{ userSelect: "none" }}
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  )
}

export default TimeBubble
