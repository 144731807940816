import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyD3tFf4JKvRAjqdLdL16G18R7TN7IIHfow",
  authDomain: "barredlist.com",
  projectId: "lunchbuddies-31290",
  storageBucket: "lunchbuddies-31290.appspot.com",
  appId: "1:238597266786:web:7ab4b3bbb6abf31beb203b",
  messagingSenderId: "238597266786",
  measurementId: "G-SWCCW44X96",
}

export const initFirebase = () => {}
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth()
const storage = getStorage(app)

export { db, auth, storage }
