import React, { useEffect, useState } from "react"
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Box,
  IconButton,
} from "@mui/material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import TodayIcon from "@mui/icons-material/Today"
import ScheduleIcon from "@mui/icons-material/Schedule"
import LocalDiningIcon from "@mui/icons-material/LocalDining"
import InfoIcon from "@mui/icons-material/Info"
import MessageIcon from "@mui/icons-material/Message"
import ReportIcon from "@mui/icons-material/Report"
import useAuth from "../hooks/useAuth"
import { Navigate, useNavigate } from "react-router-dom"
import { useGetUserQuery } from "../redux/users/userApi"
import {
  useGetRequestQuery,
  useListRequestsQuery,
} from "../redux/requests/requestApi"
import { useDeleteRequestMutation } from "../redux/requests/requestApi"
import RequestInfoBox from "../components/RequestInfoBox"
import OtherRequestCard from "../components/OtherRequestCard"
import BlockUserDialog from "../components/BlockUserDialog"
import MainLayout from "../components/MainLayout"
import { ArrowLeftIcon } from "@mui/x-date-pickers"
import { handleHours } from "../functions/functions"
import NoAvatarImg from "../images/no-avatar.png"
import { UserData } from "../redux/users/user"
import { setupRequestSubscription } from "../redux/requests/subscribe"
import { useTheme } from "@mui/material/styles"

const ConnectPage: React.FC = () => {
  const { user } = useAuth()
  const authUser = user!
  const { data: userProfile, isLoading: userLoading } =
    useGetUserQuery(authUser)
  const { data: request } = useGetRequestQuery(authUser)
  const { data: allRequests, isLoading: allRequestsLoading } =
    useListRequestsQuery({ authUser, limitValue: 20 })
  const [deleteRequest] = useDeleteRequestMutation()
  const [requestersInfo, setRequestersInfo] = useState<UserData | null>(null)
  const [requestInfoMaxed, setRequestInfoMaxed] = useState(true)
  const [openBlockDialog, setOpenBlockDialog] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    const unsub = setupRequestSubscription()

    return () => unsub()
  }, [])

  const handleRequestInfoMaxed = () => {
    setRequestInfoMaxed(!requestInfoMaxed)
  }

  const handleNavigateToMessages = (contactInfo: UserData) => {
    navigate(`/main/messages/${contactInfo.uid}`)
  }

  const handleCloseReqButton = async () => {
    try {
      await deleteRequest({ authUser })
    } catch (error) {
      console.error("Error occurred while updating request:", error)
    }
    navigate("/main/request")
  }

  const handleOpenBlockDialog = () => setOpenBlockDialog(true)
  const handleCloseBlockDialog = () => setOpenBlockDialog(false)

  return (
    <>
      {userLoading && <CircularProgress />}

      {!userLoading && userProfile == null && <Navigate to="/profile/create" />}

      {!userLoading && userProfile != null && (
        <MainLayout isLanding={false}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {requestersInfo && (
              <Box
                component="img"
                sx={{
                  display: "flex",
                  width: "20vh",
                  height: "20vh",
                  borderRadius: "50%",
                  border: "5px solid white",
                  objectFit: "cover",
                }}
                alt="Avatar"
                src={requestersInfo.photoURL || NoAvatarImg}
              />
            )}
            {!requestersInfo && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: requestInfoMaxed ? "column" : "row",
                    flexWrap: requestInfoMaxed ? "wrap" : "nowrap",
                    width: "100%",
                    height: "auto",
                    overflow: "auto",
                    paddingTop: 2,
                  }}
                >
                  <RequestInfoBox
                    text={request?.location ?? ""}
                    icon={<LocationOnIcon />}
                  />
                  <RequestInfoBox
                    text={request?.date ?? ""}
                    icon={<TodayIcon />}
                  />
                  <RequestInfoBox
                    text={request?.hours?.toString() ?? ""}
                    icon={<ScheduleIcon />}
                  />
                  <RequestInfoBox
                    text={request?.cuisines?.toString() ?? ""}
                    icon={<LocalDiningIcon />}
                  />
                  {request?.specialRequests?.map((element, index) => (
                    <RequestInfoBox
                      key={index}
                      text={element ?? ""}
                      icon={<InfoIcon />}
                    />
                  ))}
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 2,
                width: "100%",
                height: "100%",
                borderTopRightRadius: 40,
                borderTopLeftRadius: 40,
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderBottom: "1px solid #ddd",
                  paddingY: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 3,
                    height: "100%",
                  }}
                >
                  {!requestersInfo && (
                    <>
                      {requestInfoMaxed && (
                        <KeyboardArrowUpIcon
                          onClick={handleRequestInfoMaxed}
                          sx={{ cursor: "pointer" }}
                        />
                      )}
                      {!requestInfoMaxed && (
                        <KeyboardArrowDownIcon
                          onClick={handleRequestInfoMaxed}
                          sx={{ cursor: "pointer" }}
                        />
                      )}
                    </>
                  )}
                  {requestersInfo && (
                    <ArrowLeftIcon
                      onClick={() => setRequestersInfo(null)}
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                  }}
                >
                  {requestersInfo && (
                    <Typography fontSize={"x-large"} fontWeight={"bold"}>
                      {requestersInfo.displayName}
                    </Typography>
                  )}
                  {!requestersInfo && <Typography>Other Requests</Typography>}
                </Box>
                {!requestersInfo && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseReqButton}
                    sx={{ marginRight: 3 }}
                  >
                    Close My Request
                  </Button>
                )}
                {requestersInfo && (
                  <>
                    <IconButton
                      onClick={handleOpenBlockDialog}
                      sx={{ marginX: 2, color: theme.palette.error.main }}
                    >
                      <ReportIcon />
                    </IconButton>
                    <BlockUserDialog
                      open={openBlockDialog}
                      onClose={handleCloseBlockDialog}
                    />
                  </>
                )}
              </Box>
              {requestersInfo && (
                <Box>
                  {!allRequestsLoading &&
                    allRequests &&
                    allRequests.map((targetRequest) => {
                      if (targetRequest.uid === requestersInfo.uid) {
                        return (
                          <React.Fragment key={targetRequest.uid}>
                            <Box
                              sx={{
                                display: "flex",
                                flexGrow: 1,
                                height: "50vh",
                                flexDirection: "column",
                                overflow: "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  width: "100%",
                                  borderBottom: "1px solid #ddd",
                                  paddingBottom: "6px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography fontWeight={"bold"}>
                                    Age&nbsp;
                                  </Typography>
                                  <Typography>
                                    {targetRequest.userData.age}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography fontWeight={"bold"}>
                                    Pronoun&nbsp;
                                  </Typography>
                                  <Typography>
                                    {targetRequest.userData.pronoun}
                                  </Typography>
                                </Box>
                              </Box>
                              <Grid
                                container
                                textAlign={"center"}
                                height={"100%"}
                              >
                                <Grid item xs={12} marginTop={2}>
                                  <Typography
                                    fontSize={"large"}
                                    fontWeight={"bold"}
                                  >
                                    Cuisine Preferences
                                  </Typography>
                                  <Typography>
                                    {targetRequest.cuisines.join(", ")}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} marginTop={2}>
                                  <Typography
                                    fontSize={"large"}
                                    fontWeight={"bold"}
                                  >
                                    Location
                                  </Typography>
                                  <Typography>
                                    {targetRequest.location}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} marginTop={2}>
                                  <Typography
                                    fontSize={"large"}
                                    fontWeight={"bold"}
                                  >
                                    Availability
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexWrap: "wrap",
                                      paddingTop: "6px",
                                    }}
                                  >
                                    {handleHours(targetRequest.hours)}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} marginTop={2}>
                                  <Typography
                                    fontSize={"large"}
                                    fontWeight={"bold"}
                                  >
                                    Favorite Lunch Spots
                                  </Typography>
                                  <Typography>
                                    {
                                      targetRequest.userData
                                        .favoriteFoodLocations
                                    }
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  marginTop={2}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {targetRequest.specialRequests?.map(
                                    (element, index) => (
                                      <RequestInfoBox
                                        key={index}
                                        text={element ?? ""}
                                        icon={<InfoIcon />}
                                      />
                                    ),
                                  )}
                                </Grid>
                                <Grid item xs={12} marginTop={2}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      handleNavigateToMessages(
                                        targetRequest.userData,
                                      )
                                    }}
                                  >
                                    <MessageIcon
                                      sx={{ color: "white", paddingRight: 2 }}
                                    />
                                    Message
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </React.Fragment>
                        )
                      }
                      return null
                    })}
                </Box>
              )}
              {!requestersInfo && (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                    overflowY: "auto",
                  }}
                >
                  {allRequests &&
                    allRequests
                      .filter(
                        (targetRequest) => targetRequest.uid !== authUser.uid,
                      )
                      .map((targetRequest) => {
                        if (targetRequest.uid) {
                          return (
                            <Box
                              sx={{
                                width: "90%",
                                cursor: "pointer",
                                marginY: 3,
                              }}
                              onClick={async () => {
                                setRequestersInfo(targetRequest.userData)
                              }}
                              key={targetRequest.uid}
                            >
                              <OtherRequestCard
                                fullName={
                                  targetRequest.userData.displayName || ""
                                }
                                location={targetRequest.location}
                                image={targetRequest.userData.photoURL || ""}
                                hours={targetRequest.hours || []}
                                cuisines={targetRequest.cuisines || []}
                              />
                            </Box>
                          )
                        }
                        return null
                      })}
                </Box>
              )}
            </Box>
          </Box>
        </MainLayout>
      )}
    </>
  )
}

export default ConnectPage
