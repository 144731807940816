import { batch, useDispatch } from "react-redux"
import { persistor } from "../redux/store"
import { useEffect, useState } from "react"
import { User, onAuthStateChanged } from "firebase/auth"
import { auth } from "../firebase"
import { useUpsertAccountMutation } from "../redux/accountApi"
import fetchUserSettings from "../redux/users/fetchUserSettings"
import {
  clearUser,
  setHasProfile,
  setHasRequest,
  setUser,
  setUserSettings,
} from "../redux/app/appSlice"

const useFirebaseAuth = () => {
  const dispatch = useDispatch()
  const [upsertAccount] = useUpsertAccountMutation()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const clearUserLogin = () => {
      persistor.purge()
      dispatch(clearUser())
    }

    const updateAuth = async (fbUser: User | null) => {
      if (fbUser == null) {
        console.log("No account")
        clearUserLogin()
      } else if (
        !fbUser.emailVerified ||
        fbUser.isAnonymous ||
        fbUser.displayName == null ||
        fbUser.uid == null ||
        fbUser.email == null
      ) {
        console.log("Unverified account")
        clearUserLogin()
      } else {
        console.log("logged in")
        const { uid, email, displayName, photoURL } = fbUser

        const { hasRequest, hasProfile, settings } =
          await fetchUserSettings(uid)

        batch(() => {
          dispatch(setUser({ uid, displayName, photoURL }))
          dispatch(setHasRequest(hasRequest))
          dispatch(setHasProfile(hasProfile))
          dispatch(setUserSettings(settings))
        })

        // store email and account type
        upsertAccount({
          uid,
          account: { email, type: "google" },
        })
      }
    }

    const unsubscribe = onAuthStateChanged(auth, updateAuth)
    setIsLoading(false)
    return () => unsubscribe()
  }, [dispatch, upsertAccount])

  return {
    isLoading,
  }
}

export default useFirebaseAuth
