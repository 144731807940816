import React, { useState } from "react"
import { Box, Button, Typography, Link, Alert } from "@mui/material"
import MainLayout from "../components/MainLayout"
import { LoginResult, loginWithGoogle } from "../lib/auth"
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth"
import { auth } from "../firebase"

const LoginPage: React.FC = () => {
  const [loginError, setLoginError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const provider = new GoogleAuthProvider()

  const googleLogin = async () => {
    setLoginError(null)
    setIsLoading(true)
    try {
      const loginResult = await loginWithGoogle()
      if (loginResult === LoginResult.OK) return

      setLoginError(
        loginResult === LoginResult.ERR_POPUP_BLOCKED
          ? "Please check for blocked pop-ups and try again. If you're on an iPhone, allow pop-ups in Safari settings."
          : "There was a problem logging in, please try again.",
      )
      if (loginResult === LoginResult.ERR_POPUP_BLOCKED) {
        await signInWithRedirect(auth, provider)
      }
    } catch (err: any) {
      if (err.code === "auth/network-request-failed") {
        setLoginError("Network error. Please check your internet connection.")
      } else {
        setLoginError("There was a problem logging in, please try again.")
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <MainLayout isLanding={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          overflowY: "auto",
          paddingX: "40px",
          height: "100%",
        }}
      >
        <Box>
          <Typography variant="h2">Join Lunch Buddies.</Typography>
          <br />
          <Typography variant="h6">
            People in your city are looking to have a meal with someone. Find
            your lunch buddy.
          </Typography>
        </Box>
        <Box>
          <Box sx={{ mb: 2 }}>
            {loginError && (
              <Alert sx={{ fontSize: "1rem" }} severity="error">
                {loginError}
              </Alert>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={googleLogin}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Login with Google"}
          </Button>
        </Box>
        <Box
          sx={{
            paddingBottom: 2,
          }}
        >
          <Typography variant="body1">
            By signing up, you acknowledge that our&nbsp;
            <Link
              href="https://lunchbuddies.ca/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            &nbsp;applies to you.
          </Typography>
        </Box>
      </Box>
    </MainLayout>
  )
}

export default LoginPage
