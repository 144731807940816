import React from "react"
import { Box, Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { useTheme } from "@mui/material/styles"

type CheckBoxProps = {
  text: string
  checked: boolean
  onChange: () => void
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: "flex",
        width: "fit-content",
        backgroundColor: "white",
        borderRadius: "10px",
        height: "50px",
        margin: "10px",
      }}
    >
      <Box
        sx={{
          padding: 2,
          backgroundColor: props.checked
            ? theme.palette.error.main
            : theme.palette.secondary.dark,
          borderRadius: "10px 0px 0px 10px",
          width: "20px",
          cursor: "pointer",
        }}
        onClick={props.onChange}
      >
        <CheckIcon
          style={{
            display: props.checked ? "block" : "none",
          }}
        />
      </Box>
      <Typography sx={{ padding: 2 }}>
        <b>{props.text}</b>
      </Typography>
    </Box>
  )
}

export default CheckBox
