import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { AuthUser, UserProfileRecord } from "../users/user"

export async function upsertUserProfile(
  authUserRec: AuthUser,
  profile: UserProfileRecord,
): Promise<boolean> {
  const docRef = doc(db, "users", authUserRec.uid)
  const docSnap = await getDoc(docRef)

  try {
    if (docSnap.exists()) {
      // just update the profile
      await updateDoc(docRef, { profile })
    } else {
      const { uid, displayName, photoURL } = authUserRec
      const authUser = { uid, displayName, photoURL }
      await setDoc(docRef, { authUser, profile })
    }
  } catch (error) {
    console.error("upsertUserProfile: ", error)
    return false
  }

  return true
}
