import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { CreateMessageParams, createMessage } from "./createMessage"
import { ListMessageParams, listMessages } from "./listMessages"
import { Message } from "./message"
import store from "../store"

const createMessageFunc = async (params: CreateMessageParams) => {
  return {
    data: await createMessage(params),
  }
}

const listMessageFunc = async (params: ListMessageParams) => {
  return {
    data: await listMessages(params),
  }
}

export const messageApiSlice = createApi({
  reducerPath: "messageApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["Message"],
  endpoints: (builder) => ({
    createMessage: builder.mutation<string | undefined, CreateMessageParams>({
      queryFn: createMessageFunc,
      invalidatesTags: ["Message"],
    }),
    listMessages: builder.query<Message[], ListMessageParams>({
      queryFn: listMessageFunc,
      providesTags: ["Message"],
    }),
  }),
})

export const { useCreateMessageMutation, useListMessagesQuery } =
  messageApiSlice

export const resetMessagesApi = () => {
  store.dispatch(messageApiSlice.util.invalidateTags(["Message"]))
}
