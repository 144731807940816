import { DocumentReference, getDoc } from "firebase/firestore"
import { UserData, UserRecord, userDataFromRecord } from "../users/user"

export const getUser = async (
  userRef: DocumentReference,
): Promise<UserData | undefined> => {
  const userDoc = await getDoc(userRef)

  if (userDoc.exists()) {
    const userRecord = userDoc.data() as UserRecord
    const userData = userDataFromRecord(userRecord)

    return userData
  } else {
    return undefined
  }
}
