import { doc, getDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { UserSettings } from "./user"

export interface FetchUserSettingsResult {
  settings: UserSettings
  hasRequest: boolean
  hasProfile: boolean
}

const fetchUserSettings = async (
  userId: string,
): Promise<FetchUserSettingsResult> => {
  const settings: UserSettings = {
    allowRequests: false,
  }

  const result = {
    settings,
    hasRequest: false,
    hasProfile: false,
  }

  try {
    const docRef = doc(db, "requests", userId)
    const requestSnapshot = await getDoc(docRef)
    result.hasRequest = requestSnapshot.exists()

    const userDocRef = doc(db, `users/${userId}`)
    const userDocSnapshot = await getDoc(userDocRef)

    if (userDocSnapshot.exists()) {
      result.hasProfile = true

      const privateSettingsRef = doc(db, `users/${userId}/private/settings`)
      const privateSettingsDoc = await getDoc(privateSettingsRef)

      if (privateSettingsDoc.exists()) {
        const data = privateSettingsDoc.data()

        if (data) {
          if ("allowRequests" in data) {
            settings.allowRequests = data.allowRequests
          }
        }
      }
    }
  } catch (error) {
    console.log("Error fetching private settings:", error)
  }

  return result
}

export default fetchUserSettings
