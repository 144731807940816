import React, { ReactNode } from "react"
import { Box, Grid } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Navbar from "../components/Navbar"
import CoconutBg from "../../src/images/coconut-bg.png"
import FastFoodBg from "../../src/images/fast-food-bg.png"

type MainLayoutProps = {
  children: ReactNode
  isLanding: boolean
}

const MainLayout = ({ children, isLanding }: MainLayoutProps) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <div>
      <Grid
        container
        height={"100vh"}
        bgcolor={
          isLanding ? theme.palette.secondary.main : theme.palette.primary.main
        }
        sx={{
          ...(isLanding &&
            !isXs && {
              backgroundImage: `url(${CoconutBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }),
          ...(!isLanding &&
            !isXs && {
              backgroundImage: `url(${FastFoodBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }),
        }}
        textAlign={"center"}
      >
        {isLanding && (
          <Grid item xs={12} height={"70px"}>
            <Box
              component="div"
              style={{
                backgroundColor: theme.palette.secondary.main,
                borderTop: `${theme.palette.secondary.dark} 24px solid`,
                borderBottom: `${theme.palette.secondary.dark} 4px solid`,
                top: "0px",
                height: "4px",
                width: "100%",
              }}
            />
          </Grid>
        )}
        {!isLanding && (
          <>
            <Grid
              item
              xs={0}
              sm={1}
              md={2}
              lg={3}
              xl={4}
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            ></Grid>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4} height={"70px"}>
              <Navbar />
            </Grid>
            <Grid
              item
              xs={0}
              sm={1}
              md={2}
              lg={3}
              xl={4}
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            ></Grid>
          </>
        )}
        <Grid
          item
          xs={0}
          sm={1}
          md={2}
          lg={3}
          xl={4}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          xl={4}
          sx={{
            display: "flex",
            height: `calc(100% - 70px)`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {children}
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={1}
          md={2}
          lg={3}
          xl={4}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        ></Grid>
      </Grid>
    </div>
  )
}

export default MainLayout
