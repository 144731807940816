import React from "react"
import { Box, Typography } from "@mui/material"
import { handleHours } from "../functions/functions"

type OtherRequestCardProps = {
  fullName: string
  location: string
  image: string
  hours: string[]
  cuisines: string[]
}

const OtherRequestCard: React.FC<OtherRequestCardProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        sx={{
          display: "flex",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          objectFit: "cover",
          marginRight: 2,
          flexShrink: 0,
        }}
        alt="Avatar"
        src={props.image}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
          color: "black",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography fontFamily={"sans-serif"}>{props.fullName}</Typography>
          <Typography fontFamily={"sans-serif"} fontSize={"small"}>
            {props.location}
          </Typography>
        </Box>
        <Typography
          fontFamily={"sans-serif"}
          color={"gray"}
          textAlign={"start"}
        >
          {props.cuisines.join(", ")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            marginTop: 1,
          }}
        >
          {handleHours(props.hours)}
        </Box>
      </Box>
    </Box>
  )
}

export default OtherRequestCard
