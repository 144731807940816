import { createTheme } from "@mui/material"

export const theme = createTheme({
  palette: {
    primary: {
      light: "#2A6BB7",
      main: "#225996",
      dark: "#1A4474",
    },
    secondary: {
      light: "#FFE433",
      main: "#FFDF00",
      dark: "#FFC000",
    },
    error: {
      light: "#FF3385",
      main: "#FF0366",
      dark: "#E0005A",
    },
  },
  typography: {
    fontFamily: "'Palanquin', sans-serif",
    h1: {
      fontFamily: "'Palanquin Dark', sans-serif",
    },
    h2: {
      fontFamily: "'Palanquin Dark', sans-serif",
    },
    h3: {
      fontFamily: "'Palanquin Dark', sans-serif",
    },
    h4: {
      fontFamily: "'Palanquin Dark', sans-serif",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "30px",
          padding: "15px 32px",
          textTransform: "none",
          fontSize: "16px",
          margin: "4px 2px",
          cursor: "pointer",
        },
        text: {
          textTransform: "none",
          fontSize: "x-large",
          cursor: "pointer",
          fontWeight: "bold",
        },
      },
      variants: [
        {
          props: { color: "secondary" },
          style: {
            backgroundColor: "#FFDF00",
            color: "black",
            "&:hover": {
              backgroundColor: "#FFC000",
            },
          },
        },
        {
          props: { color: "error" },
          style: {
            backgroundColor: "#FF0366",
            color: "white",
            "&:hover": {
              backgroundColor: "#E0005A",
            },
          },
        },
      ],
    },
  },
})
