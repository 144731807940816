import { combineReducers, configureStore } from "@reduxjs/toolkit"
import appReducer from "./app/appSlice"
import { userApiSlice } from "./users/userApi"
import { requestApiSlice } from "./requests/requestApi"
import { messageApiSlice } from "./messages/messageApi"
import { accountApiSlice } from "./accountApi"
import { notificationApiSlice } from "./notifications/notificationApi"
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

const persistConfig = {
  key: "root",
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  app: appReducer,
  [notificationApiSlice.reducerPath]: notificationApiSlice.reducer,
  [userApiSlice.reducerPath]: userApiSlice.reducer,
  [requestApiSlice.reducerPath]: requestApiSlice.reducer,
  [messageApiSlice.reducerPath]: messageApiSlice.reducer,
  [accountApiSlice.reducerPath]: accountApiSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(notificationApiSlice.middleware)
      .concat(userApiSlice.middleware)
      .concat(requestApiSlice.middleware)
      .concat(messageApiSlice.middleware)
      .concat(accountApiSlice.middleware),
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
