import React, { useEffect } from "react"
import { Box, Button, Typography } from "@mui/material"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import MainLayout from "../components/MainLayout"
import { useTheme } from "@mui/material/styles"
import dayjs, { Dayjs } from "dayjs"
import { logOut } from "../lib/auth"

const ComingSoonPage: React.FC = () => {
  const launchDate: Dayjs = dayjs("2024-05-22")
  const currentDate: Dayjs = dayjs()
  const daysToLaunch: number = launchDate.diff(currentDate, "days")
  const theme = useTheme()

  useEffect(() => {
    logOut()
  }, [])

  return (
    <MainLayout isLanding={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          overflowY: "auto",
          paddingX: "40px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            color={theme.palette.error.main}
            sx={{
              fontSize: {
                xs: "2rem",
                sm: "3rem",
                md: "4rem",
              },
            }}
          >
            {daysToLaunch}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "3rem",
              },
            }}
          >
            &nbsp; Day{daysToLaunch > 1 && "s"} to Go!
          </Typography>
        </Box>
        <Box>
          <br />
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.3rem",
                md: "1.5rem",
              },
            }}
          >
            <b>Welcome to Lunch Buddies.</b>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.3rem",
                md: "1.5rem",
              },
            }}
          >
            We will launch the app on{" "}
            <span style={{ color: theme.palette.error.main }}>May 22nd</span>{" "}
            and are so glad to have you join us on our greatest milestone so
            far.
          </Typography>
          <br />
        </Box>
        <Box>
          <Typography variant="body1">
            <b>What to expect</b>
          </Typography>
          <Typography variant="body1">
            At 9:30 AM, the app will go live, and you will be able to see other
            lunch buddies. Do not worry, we will send you an email prompt to
            check the app. You will have one hour to communicate with others
            through the app and confirm your lunch buddy for the day. Remember,
            the goal is to go to lunch with someone. Nobody has to eat alone. We
            can't wait to see you then!
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" color={theme.palette.error.main}>
          <a
            href="https://lunchbuddies.ca/"
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
          >
            <b>lunchbuddies.ca</b>
          </a>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <br />
          <Typography variant="h6" sx={{ paddingBottom: "10px" }}>
            Follow Us on Social Media
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                color: "black",
                marginRight: "20px",
              }}
              href="https://www.facebook.com/lunchbuddiescanada"
              target="_blank"
            >
              <FacebookIcon fontSize="large" />
            </a>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="https://www.instagram.com/lunch_buddies_app/"
              target="_blank"
            >
              <InstagramIcon fontSize="large" />
            </a>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  )
}

export default ComingSoonPage
