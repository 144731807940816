import {
  QueryDocumentSnapshot,
  collection,
  doc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore"
import { db } from "../../firebase"
import {
  LunchRequest,
  LunchRequestRecord,
  convertLunchRequestRecordToLunchRequest,
} from "./request"
import { getUser } from "../users/getUser"
import { AuthUser } from "../users/user"

export const listRequests = async (
  authUser: AuthUser,
  limitValue: number,
  startAfterValue?: QueryDocumentSnapshot,
): Promise<LunchRequest[]> => {
  let q = query(
    collection(db, "requests"),
    where("uid", "!=", authUser.uid),
    limit(limitValue),
  )

  //if (startAfterValue != null) {
  // q = query(q, startAfter(startAfterValue))
  //}

  const querySnapshot = await getDocs(q)

  // Create an array of promises for fetching user data
  const lunchRequestPromises = querySnapshot.docs.map(
    async (lunchRequestDoc) => {
      const lunchRequestRecord: LunchRequestRecord =
        lunchRequestDoc.data() as LunchRequestRecord

      // Fetch the user data using the userRef
      const userRef = doc(collection(db, "users"), lunchRequestDoc.id)
      const userData = await getUser(userRef)

      if (userData == null) {
        return undefined
      } else {
        const lunchRequest = convertLunchRequestRecordToLunchRequest(
          lunchRequestRecord,
          lunchRequestDoc.id,
          userData,
        )

        return lunchRequest
      }
    },
  )

  // Fetch all user data in parallel
  const lunchRequestsResult = await Promise.all(lunchRequestPromises)

  const lunchRequests = lunchRequestsResult.filter(
    (request) => request !== undefined,
  ) as LunchRequest[]

  return lunchRequests
}
