import React, { useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import MainLayout from "../components/MainLayout"
import Slide1 from "../images/login_slide_1.jpg"
import Slide2 from "../images/login_slide_2.jpg"
import Slide3 from "../images/login_slide_3.jpg"
import Slide4 from "../images/login_slide_4.jpg"

const slides = [
  {
    image: Slide1,
    text: "Meet lunch buddies at your local coffee shop, restaurant, or park",
  },
  {
    image: Slide2,
    text: "Rediscover human interaction without professional pressure or dating agenda",
  },
  {
    image: Slide3,
    text: '"I wish I had someone to have lunch with" - said everybody at least once in their life',
  },
  {
    image: Slide4,
    text: "It's all about food and humans. Let's grab a bite!",
  },
]

const DotIndicator = ({ active }: { active: boolean }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: active ? "orange" : "white",
        margin: "0 7px",
      }}
    />
  </div>
)

const Slide = ({ slide }: { slide: any }) => (
  <Box>
    <img
      src={slide.image}
      alt="Slide"
      style={{
        width: "100%",
        maxHeight: "50vh",
        objectFit: "cover",
      }}
    />
    <Typography variant="h6" sx={{ paddingX: "20px", marginTop: "40px" }}>
      <b>{slide.text}</b>
    </Typography>
  </Box>
)

const WelcomePage: React.FC = () => {
  const navigate = useNavigate()
  const [menuNumber, setMenuNumber] = useState(0)

  const skipToLoginPage = () => {
    navigate("/login")
  }

  const handleNext = () => {
    setMenuNumber((prevNumber) => Math.min(prevNumber + 1, slides.length - 1))
  }

  return (
    <MainLayout isLanding={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Slide slide={slides[menuNumber]} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
          paddingBottom: 4,
        }}
      >
        <Button variant="text" color="primary" onClick={skipToLoginPage}>
          Skip
        </Button>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: 2,
            paddingX: 2,
          }}
        >
          {slides.map((slide, index) => (
            <DotIndicator key={index} active={index === menuNumber} />
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={
            menuNumber === slides.length - 1 ? skipToLoginPage : handleNext
          }
        >
          {menuNumber === slides.length - 1 ? "Login" : "Next"}
        </Button>
      </Box>
    </MainLayout>
  )
}

export default WelcomePage
