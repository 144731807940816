import { Badge } from "@mui/material"
import router from "../routing/router"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { useSelector } from "react-redux"
import {
  selectHasUnreadNotifications,
  selectIsLoggedIn,
} from "../redux/app/appSelectors"

const NotificationsBadge = () => {
  const goToNotificationsPage = () => router.navigate("/main/notifications")
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const hasUnreadNotifications = useSelector(selectHasUnreadNotifications)

  return (
    <Badge
      invisible={!isLoggedIn || !hasUnreadNotifications}
      variant="dot"
      color="error"
      overlap="circular"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        marginRight: "20px",
      }}
    >
      <NotificationsIcon
        sx={{ color: "white", cursor: "pointer" }}
        onClick={goToNotificationsPage}
      />
    </Badge>
  )
}

export default NotificationsBadge
