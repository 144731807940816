import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore"
import { AuthUser } from "../users/user"
import { db } from "../../firebase"
import {
  Message,
  MessageRecord,
  convertMessageRecordtoMessage,
} from "./message"

export interface ListMessageParams {
  otherUserUid: string
  authUser: AuthUser
  limitValue: number
  startAfterValue?: QueryDocumentSnapshot
}

export const listMessages = async ({
  authUser,
  otherUserUid,
  limitValue,
  startAfterValue,
}: ListMessageParams): Promise<Message[]> => {
  const participantKey = [authUser.uid, otherUserUid].sort().join("_")

  let messagesQuery = query(
    collection(db, "messages"),
    where("participantKey", "==", participantKey),
    orderBy("sentAt", "desc"),
    limit(limitValue),
  )

  if (startAfterValue != null) {
    messagesQuery = query(messagesQuery, startAfter(startAfterValue))
  }

  const querySnapshot = await getDocs(messagesQuery)
  const messages: Message[] = []
  querySnapshot.forEach((doc) => {
    const messageRecord: MessageRecord = doc.data() as MessageRecord

    const message: Message = convertMessageRecordtoMessage(
      authUser,
      messageRecord,
    )

    messages.push(message)
  })
  return messages
}
