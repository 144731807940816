import { onSnapshot } from "firebase/firestore"
import { makeUserRef, resetUserApi } from "./userApi"
import store from "../store"
import { setHasProfile } from "../app/appSlice"

export const setupUserSubscription = (userUid: string) => {
  const userRef = makeUserRef(userUid)

  const unsubscribe = onSnapshot(userRef, (userSnapshot) => {
    resetUserApi()

    const hasProfile = userSnapshot.exists()
    store.dispatch(setHasProfile(hasProfile))
  })

  return unsubscribe
}
