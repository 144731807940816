import React, { useEffect, useState } from "react"
import MainLayout from "../components/MainLayout"
import BlockUserDialog from "../components/BlockUserDialog"
import EmojiPicker from "emoji-picker-react"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import SendIcon from "@mui/icons-material/Send"
import ReportIcon from "@mui/icons-material/Report"
import {
  IconButton,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Modal,
} from "@mui/material"

import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions"
import useAuth from "../hooks/useAuth"
import { useNavigate, useParams } from "react-router-dom"
import {
  useCreateMessageMutation,
  useListMessagesQuery,
} from "../redux/messages/messageApi"
import { setupMessageSubscription } from "../redux/messages/subscribe"
import { useGetUserQuery } from "../redux/users/userApi"
import { setMessagingWithUid } from "../redux/app/appSlice"
import { useDispatch } from "react-redux"

const MessagesPage = () => {
  const dispatch = useDispatch()
  const { fromUserUid } = useParams()
  const otherUserUid = fromUserUid!
  const { user } = useAuth()
  const authUser = user!
  const navigate = useNavigate()
  const [createMessageMutation] = useCreateMessageMutation()
  const [newMessage, setNewMessage] = useState<string>("")
  const [openBlockDialog, setOpenBlockDialog] = useState(false)
  const {
    data: userData,
    isLoading: isUserDataLoading,
    error: errorLoadingUserData,
  } = useGetUserQuery({ uid: otherUserUid })
  const [openEmojiModal, setOpenEmojiModal] = React.useState(false)
  const {
    data: messages,
    isLoading,
    error,
  } = useListMessagesQuery({
    authUser,
    otherUserUid,
    limitValue: 10,
  })

  const handleSendMessage = async () => {
    try {
      if (!newMessage.trim()) return
      await createMessageMutation({
        toUserUid: otherUserUid,
        message: newMessage,
        authUser: authUser,
      })

      setNewMessage("")
    } catch (error) {
      console.error("Error sending message:", error)
    }
  }

  const handleOpenBlockDialog = () => setOpenBlockDialog(true)
  const handleCloseBlockDialog = () => setOpenBlockDialog(false)

  const handleOpenEmojiModal = () => setOpenEmojiModal(true)
  const handleCloseEmojiModal = () => setOpenEmojiModal(false)

  const handleEmoji = (event: any) => {
    setNewMessage(newMessage + event.emoji)
    handleCloseEmojiModal()
  }

  const goPreviousPage = () => {
    navigate(-1)
  }

  useEffect(() => {
    const unsub = setupMessageSubscription(authUser.uid, otherUserUid)

    return () => unsub()
  }, [authUser.uid, otherUserUid])

  useEffect(() => {
    dispatch(setMessagingWithUid(otherUserUid))

    return () => {
      dispatch(setMessagingWithUid(null))
    }
  }, [authUser.uid, dispatch, otherUserUid])

  return (
    <MainLayout isLanding={false}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100px",
            color: "white",
          }}
        >
          <IconButton
            onClick={goPreviousPage}
            sx={{ paddingLeft: 2, color: "white" }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          {!isUserDataLoading && userData != null && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  border: "5px solid white",
                }}
                alt="Avatar"
                src={userData?.photoURL}
              />
              <Typography variant="h5" paddingLeft={2}>
                {userData?.displayName}
              </Typography>
            </Box>
          )}
          <IconButton
            onClick={handleOpenBlockDialog}
            sx={{ marginX: 2, color: "white" }}
          >
            <ReportIcon />
          </IconButton>
          <BlockUserDialog
            open={openBlockDialog}
            onClose={handleCloseBlockDialog}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column-reverse",
              paddingY: "20px",
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {isLoading && <CircularProgress size={60} />}
            {messages &&
              !isLoading &&
              messages.map((message, index) => (
                <Box
                  key={index}
                  sx={{
                    alignSelf: message.fromMe ? "flex-end" : "flex-start",
                    backgroundColor: message.fromMe
                      ? "lightblue"
                      : "lightgreen",
                    width: "fit-content",
                    padding: "8px",
                    borderRadius: "10px",
                    margin: "5px",
                    marginLeft: "30px",
                    marginRight: "30px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: message.fromMe ? "right" : "left" }}
                  >
                    {message.message}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            display="flex"
            sx={{
              width: "90%",
              paddingY: "20px",
            }}
          >
            <IconButton onClick={handleOpenEmojiModal}>
              <EmojiEmotionsIcon />
            </IconButton>
            <Modal
              open={openEmojiModal}
              onClose={handleCloseEmojiModal}
              aria-labelledby="modal-emoji-picker"
              aria-describedby="modal-emoji-picker-component"
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <EmojiPicker onEmojiClick={handleEmoji} lazyLoadEmojis={true} />
              </Box>
            </Modal>
            <TextField
              fullWidth
              variant="outlined"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <IconButton
              color="primary"
              onClick={handleSendMessage}
              disabled={!newMessage.trim()}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  )
}

export default MessagesPage
