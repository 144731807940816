import { deleteDoc, doc } from "firebase/firestore"

import { db } from "../../firebase"
import { AuthUser } from "../users/user"

export const deleteNotification = async (
  authUser: AuthUser,
  notificationId: string,
): Promise<boolean> => {
  try {
    const notificationDocRef = doc(
      db,
      `users/${authUser.uid}/notifications/${notificationId}`,
    )
    await deleteDoc(notificationDocRef)
    return true
  } catch (error) {
    console.error("Failed to delete notification:", error)
    return false
  }
}
