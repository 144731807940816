import { addDoc, collection } from "firebase/firestore"
import { AuthUser } from "../users/user"
import { db } from "../../firebase"
import { makeNewMessageRecord } from "./message"

export interface CreateMessageParams {
  toUserUid: string
  message: string
  authUser: AuthUser
}

export const createMessage = async ({
  authUser,
  toUserUid,
  message,
}: CreateMessageParams): Promise<string> => {
  const fromUserUid = authUser.uid

  const newMessageRecord = makeNewMessageRecord(fromUserUid, toUserUid, message)

  try {
    const docRef = await addDoc(collection(db, "messages"), newMessageRecord)
    return docRef.id
  } catch (error) {
    console.error("Error adding document: ", error)
    throw error // will be handled by redux
  }
}
