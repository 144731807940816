import { collection, deleteDoc, doc } from "firebase/firestore"
import { db } from "../../firebase"
import { AuthUser } from "../users/user"

export interface DeleteRequestParams {
  authUser: AuthUser
}
export const deleteRequest = async ({
  authUser,
}: DeleteRequestParams): Promise<void> => {
  const requestId = authUser.uid
  const docRef = doc(collection(db, "requests"), requestId)
  try {
    await deleteDoc(docRef)
  } catch (e) {
    console.error("Error deleting request: ", e)
    throw e // will be handled by redux
  }
}
