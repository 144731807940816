import {
  collection,
  query,
  onSnapshot,
  limit,
  orderBy,
  getDoc,
} from "firebase/firestore"
import { db } from "../../firebase"
import { resetNotificationApi } from "./notificationApi"
import store from "../store"
import {
  clearUnreadNotifications,
  setNotificationFromUid,
} from "../app/appSlice"
import { makeUserRef } from "../users/userApi"

export const setupNotificationSubscription = (authUserUid: string) => {
  const notificationsQuery = query(
    collection(db, `users/${authUserUid}/notifications`),
    limit(1),
    orderBy("timestamp", "desc"),
  )

  const unsubscribe = onSnapshot(
    notificationsQuery,
    async (querySnapshot) => {
      // Whenever there's a change in the snapshot, invalidate the 'Notification' tag
      resetNotificationApi()

      if (querySnapshot.size > 0) {
        const record = querySnapshot.docs[0]

        const fromUserUid = record.id
        const userRef = makeUserRef(fromUserUid)
        const userDoc = await getDoc(userRef)

        if (!userDoc.exists()) {
          store.dispatch(clearUnreadNotifications())
        } else {
          store.dispatch(setNotificationFromUid(fromUserUid))
        }
      } else {
        store.dispatch(clearUnreadNotifications())
      }
    },
    (error) => {
      console.error("Failed to fetch notifications:", error)
    },
  )

  // Return the unsubscribe function so it can be called to stop listening to changes
  return unsubscribe
}
