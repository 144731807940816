import { Navigate, Outlet } from "react-router-dom"
import {
  selectAllowRequests,
  selectHasProfile,
  selectHasRequest,
  selectIsLoggedIn,
} from "../redux/app/appSelectors"
import { useSelector } from "react-redux"

export const HomeLayout = () => {
  const loggedIn = useSelector(selectIsLoggedIn)
  const hasProfile = useSelector(selectHasProfile)
  const allowRequests = useSelector(selectAllowRequests)
  const hasRequest = useSelector(selectHasRequest)

  if (loggedIn) {
    if (!hasProfile) {
      return <Navigate to="/profile/create" />
    } else if (allowRequests) {
      if (hasRequest) {
        return <Navigate to="/main/connect" />
      } else {
        return <Navigate to="/main/request" />
      }
    } else {
      return <Navigate to="/coming-soon" />
    }
  }

  return <Outlet />
}
