import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  doc,
} from "firebase/firestore"
import { db } from "../../firebase"
import {
  Notification,
  NotificationRecord,
  convertNotificationRecordtoNotification,
} from "./notification"
import { getUser } from "../users/getUser"
import { AuthUser } from "../users/user"

export const listNotifications = async (
  authUser: AuthUser,
  limitValue: number,
  startAfterValue?: QueryDocumentSnapshot,
): Promise<Notification[]> => {
  let notificationsQuery = query(
    collection(db, `users/${authUser.uid}/notifications`),
    orderBy("timestamp", "desc"),
    limit(limitValue),
  )

  if (startAfterValue != null) {
    notificationsQuery = query(notificationsQuery, startAfter(startAfterValue))
  }

  const querySnapshot = await getDocs(notificationsQuery)

  const notificationPromises = querySnapshot.docs.map(
    async (notificationDoc) => {
      const notificationRecord: NotificationRecord =
        notificationDoc.data() as NotificationRecord

      // Fetch the user data using the userRef
      const userRef = doc(collection(db, "users"), notificationDoc.id)
      const userData = await getUser(userRef)

      if (userData == null) {
        return undefined
      } else {
        const notification: Notification =
          convertNotificationRecordtoNotification(
            notificationDoc.id,
            notificationRecord,
            userData,
          )

        return notification
      }
    },
  )
  // Fetch all user data in parallel
  const notificationsResults = await Promise.all(notificationPromises)

  const notifications = notificationsResults.filter(
    (result) => result !== undefined,
  ) as Notification[]

  return notifications
}
