import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  selectAllowRequests,
  selectHasProfile,
  selectIsLoggedIn,
} from "../redux/app/appSelectors"

const ProtectedConnectLayout = () => {
  const loggedIn = useSelector(selectIsLoggedIn)
  const hasProfile = useSelector(selectHasProfile)
  const allowRequests = useSelector(selectAllowRequests)

  if (!loggedIn) {
    return <Navigate to="/" />
  }

  if (!hasProfile) {
    return <Navigate to="/profile/create" />
  }

  if (!allowRequests) {
    return <Navigate to="/coming-soon" />
  }

  return <Outlet />
}

export default ProtectedConnectLayout
