import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import store from "../store"
import { deleteNotification } from "./deleteNotification"
import { listNotifications } from "./listNotifications"
import { Notification } from "./notification"

import { QueryDocumentSnapshot } from "firebase/firestore"
import { AuthUser } from "../users/user"

export interface DeleteNotificationParams {
  authUser: AuthUser
  notificationId: string
}
const deleteNotificationFunc = async ({
  authUser,
  notificationId,
}: DeleteNotificationParams) => {
  return {
    data: await deleteNotification(authUser, notificationId),
  }
}

export interface ListNotificationParams {
  authUser: AuthUser
  limitValue: number
  startAfterValue?: QueryDocumentSnapshot
}

const listNotificationFunc = async ({
  authUser,
  limitValue,
  startAfterValue,
}: ListNotificationParams) => {
  return {
    data: await listNotifications(authUser, limitValue, startAfterValue),
  }
}

interface HasNotificationParams {
  authUser: AuthUser
}

const hasNotificationsFunc = async ({ authUser }: HasNotificationParams) => {
  const notifications = await listNotifications(authUser, 1)
  return { data: notifications.length > 0 }
}

export const notificationApiSlice = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["Notification"],
  endpoints: (builder) => ({
    deleteNotification: builder.mutation<boolean, DeleteNotificationParams>({
      queryFn: deleteNotificationFunc,
      invalidatesTags: ["Notification"],
    }),
    listNotifications: builder.query<Notification[], ListNotificationParams>({
      queryFn: listNotificationFunc,
      providesTags: ["Notification"],
    }),
    hasNotifications: builder.query<boolean, HasNotificationParams>({
      queryFn: hasNotificationsFunc,
      providesTags: ["Notification"],
    }),
  }),
})

export const {
  useDeleteNotificationMutation,
  useListNotificationsQuery,
  useHasNotificationsQuery,
} = notificationApiSlice

export const resetNotificationApi = () => {
  store.dispatch(notificationApiSlice.util.invalidateTags(["Notification"]))
}
