import { runTransaction, doc, getDoc } from "firebase/firestore"
import { db } from "../../firebase"
import {
  LunchRequestData,
  convertLunchRequestDataToLunchRequestRecord,
} from "./request"
import { AuthUser } from "../users/user"

export interface UpsertRequestParams {
  authUser: AuthUser
  requestData: LunchRequestData
}
export const upsertRequest = async ({
  authUser,
  requestData,
}: UpsertRequestParams): Promise<boolean> => {
  const uid = authUser.uid
  const docRef = doc(db, "requests", uid)
  const requestRecord = convertLunchRequestDataToLunchRequestRecord(
    uid,
    requestData,
  )
  try {
    await runTransaction(db, async (transaction) => {
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        await transaction.update(docRef, requestRecord as { [x: string]: any })
      } else {
        await transaction.set(docRef, requestRecord)
      }
    })

    return true
  } catch (error) {
    console.error("Unable to upsert Request ", error)
    throw error // will be handled by redux
  }
}
