import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { LunchRequest } from "./request"
import { listRequests } from "./listRequests"
import { GetRequestParams, getRequest } from "./getRequest"
import { UpsertRequestParams, upsertRequest } from "./upsertRequest"
import { DeleteRequestParams, deleteRequest } from "./deleteRequest"
import { QueryDocumentSnapshot, collection, doc } from "firebase/firestore"
import store from "../store"
import { db } from "../../firebase"
import { AuthUser } from "../users/user"

export interface ListRequestsParams {
  authUser: AuthUser
  limitValue: number
  startAfterValue?: QueryDocumentSnapshot
}

const listRequestsFunc = async ({
  authUser,
  limitValue,
  startAfterValue,
}: ListRequestsParams) => {
  return {
    data: await listRequests(authUser, limitValue, startAfterValue),
  }
}

const getRequestFunc = async (params: GetRequestParams) => {
  return {
    data: await getRequest(params),
  }
}

const upsertRequestFunc = async (params: UpsertRequestParams) => {
  return {
    data: await upsertRequest(params),
  }
}
const deleteRequestFunc = async (params: DeleteRequestParams) => {
  return {
    data: await deleteRequest(params),
  }
}

export const requestApiSlice = createApi({
  reducerPath: "requestApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["LunchRequest"],
  endpoints: (builder) => ({
    getRequest: builder.query<LunchRequest | undefined, GetRequestParams>({
      queryFn: getRequestFunc,
      providesTags: ["LunchRequest"],
    }),
    listRequests: builder.query<LunchRequest[], ListRequestsParams>({
      queryFn: listRequestsFunc,
      providesTags: ["LunchRequest"],
    }),
    upsertRequest: builder.mutation<boolean, UpsertRequestParams>({
      queryFn: upsertRequestFunc,
      invalidatesTags: ["LunchRequest"],
    }),
    deleteRequest: builder.mutation<void, DeleteRequestParams>({
      queryFn: deleteRequestFunc,
      invalidatesTags: ["LunchRequest"],
    }),
  }),
})

export const {
  useGetRequestQuery,
  useListRequestsQuery,
  useUpsertRequestMutation,
  useDeleteRequestMutation,
} = requestApiSlice

export const resetRequestApi = () => {
  store.dispatch(requestApiSlice.util.invalidateTags(["LunchRequest"]))
}

export const makeRequestRef = (uid: string) =>
  doc(collection(db, "requests"), uid)
