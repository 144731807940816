import React from "react"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  DialogContentText,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { Navigate, useNavigate } from "react-router-dom"
import router from "../routing/router"
import { useDeleteUserMutation, useGetUserQuery } from "../redux/users/userApi"
import useAuth from "../hooks/useAuth"
import MainLayout from "../components/MainLayout"
import NoAvatarImg from "../images/no-avatar.png"
import { logOut } from "../lib/auth"

const ProfilePage: React.FC = () => {
  const { user } = useAuth()
  const authUser = user!
  const { data: userData, isLoading } = useGetUserQuery(authUser)
  const [deleteUser] = useDeleteUserMutation()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(!deleteModalOpen)
  }

  const deleteProfile = async () => {
    await deleteUser(authUser)
    logOut()
    setDeleteModalOpen(false)
    router.navigate("/main")
  }

  const goPreviousPage = () => {
    navigate(-1)
  }

  return (
    <>
      {isLoading && <CircularProgress />}

      {!isLoading && userData == null && <Navigate to="/profile/create" />}

      {!isLoading && userData != null && (
        <MainLayout isLanding={false}>
          <Box
            component="img"
            sx={{
              display: "flex",
              width: "20vh",
              height: "20vh",
              borderRadius: "50%",
              border: "5px solid white",
              objectFit: "cover",
            }}
            alt="Avatar"
            src={userData.photoURL ? userData.photoURL : NoAvatarImg}
          />
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 5,
              width: "100%",
              borderTopRightRadius: 40,
              borderTopLeftRadius: 40,
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                borderBottom: "1px solid #ddd",
                paddingY: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 3,
                  height: "100%",
                }}
              >
                <ArrowBackIosIcon
                  onClick={goPreviousPage}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={"x-large"} fontWeight={"bold"}>
                  {userData.displayName}
                </Typography>
              </Box>
            </Box>
            <React.Fragment key={authUser.uid}>
              <Grid
                container
                textAlign={"center"}
                height={"100%"}
                marginTop={5}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Age</Typography>
                  <Typography>{userData.age ?? "--"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Pronoun</Typography>
                  <Typography>
                    {userData.pronoun ? userData.pronoun : "---/---"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={"large"} fontWeight={"bold"}>
                    Favorite Food Locations
                  </Typography>
                  <Typography>
                    {userData.favoriteFoodLocations
                      ? userData.favoriteFoodLocations
                      : "None"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => router.navigate("/profile/create")}
                  >
                    <EditIcon sx={{ color: "white", paddingRight: 2 }} />
                    Edit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteModalOpen}
                  >
                    <DeleteForeverIcon
                      sx={{ color: "white", paddingRight: 2 }}
                    />
                    Delete
                  </Button>
                  <Dialog
                    open={deleteModalOpen}
                    onClose={handleDeleteModalOpen}
                    aria-labelledby="profile-delete-dialog-title"
                  >
                    <DialogTitle id="profile-delete-dialog-title">
                      {"Are you sure you want to delete your profile?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This action will delete your profile.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        onClick={handleDeleteModalOpen}
                        autoFocus
                        variant="text"
                      >
                        Cancel
                      </Button>
                      <Button
                        autoFocus
                        onClick={deleteProfile}
                        variant="contained"
                        color="error"
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </React.Fragment>
          </Box>
        </MainLayout>
      )}
    </>
  )
}

export default ProfilePage
