import { Outlet } from "react-router-dom"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectIsLoggedIn } from "../redux/app/appSelectors"

const ProtectedLayout = () => {
  const loggedIn = useSelector(selectIsLoggedIn)

  if (!loggedIn) {
    return <Navigate to="/" />
  }

  return <Outlet />
}

export default ProtectedLayout
