import { useEffect } from "react"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { IconButton, Typography, Box, CircularProgress } from "@mui/material"
import useAuth from "../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import MainLayout from "../components/MainLayout"
import { useListNotificationsQuery } from "../redux/notifications/notificationApi"
import MessageSenderCard from "../components/MessageSenderCard"
import NoAvatarImg from "../images/no-avatar.png"
import { clearUnreadNotifications } from "../redux/app/appSlice"
import { useDispatch } from "react-redux"

const NotificationsPage = () => {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const authUser = user!
  const navigate = useNavigate()

  const goPreviousPage = () => {
    navigate(-1)
  }

  const handleNotificationClick = (senderId: string) => {
    navigate(`/main/messages/${senderId}`)
  }

  useEffect(() => {
    dispatch(clearUnreadNotifications())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: notifications,
    isLoading,
    error,
  } = useListNotificationsQuery({
    authUser,
    limitValue: 10,
  })
  return (
    <MainLayout isLanding={false}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100px",
            color: "white",
          }}
        >
          <IconButton
            onClick={goPreviousPage}
            sx={{ paddingLeft: 2, color: "white" }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h5" paddingLeft={2}>
            Notifications
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "white",
          }}
        >
          {isLoading && <CircularProgress size={"3rem"} />}
          {!isLoading &&
            (notifications == null || notifications.length === 0) && (
              <Typography sx={{ mt: 5, fontSize: 25 }}>
                You have no notifications.
              </Typography>
            )}
          {!isLoading &&
            notifications &&
            notifications.map((notification, index) => (
              <MessageSenderCard
                key={index}
                senderId={notification.fromUserData.uid}
                fullName={notification.fromUserData.displayName}
                image={notification.fromUserData.photoURL || NoAvatarImg}
                lastMessage={notification.message}
                onClick={() =>
                  handleNotificationClick(notification.fromUserData.uid)
                }
              />
            ))}
        </Box>
      </Box>
    </MainLayout>
  )
}

export default NotificationsPage
