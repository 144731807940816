import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { collection, doc, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { AuthUser, UserData, UserProfileRecord } from "./user"
import { getUser } from "./getUser"
import { upsertUserProfile } from "./upsertUserProfile"
import store from "../store"

export interface GetUserParams {
  uid: string
}

const getUserFunc = async ({ uid }: GetUserParams) => {
  if (uid === "") {
    return { data: undefined }
  }
  return {
    data: await getUser(doc(db, "users", uid)),
  }
}

export interface UpsertUserProfileParams {
  authUser: AuthUser
  profile: UserProfileRecord
}

const upsertUserProfileFunc = async (params: UpsertUserProfileParams) => {
  return {
    data: await upsertUserProfile(params.authUser, params.profile),
  }
}

export const userApiSlice = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<UserData | undefined, GetUserParams>({
      queryFn: getUserFunc,
      providesTags: ["User"],
    }),
    upsertUserProfile: builder.mutation<boolean, UpsertUserProfileParams>({
      queryFn: upsertUserProfileFunc,
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation<void, AuthUser>({
      queryFn: async (authUser: AuthUser) => {
        const docRef = doc(collection(db, "users"), authUser.uid)
        await deleteDoc(docRef)
        return { data: undefined, meta: {} }
      },
      invalidatesTags: ["User"],
    }),
  }),
})

export const {
  useGetUserQuery,
  useUpsertUserProfileMutation,
  useDeleteUserMutation,
} = userApiSlice

export const makeUserRef = (uid: string) => doc(collection(db, "users"), uid)

export const resetUserApi = () => {
  store.dispatch(userApiSlice.util.invalidateTags(["User"]))
}
