import React, { useEffect, useState } from "react"
import { Box, Button, Typography, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import CheckBox from "../components/CheckBox"
import MainLayout from "../components/MainLayout"
import ImagePicker from "../components/ImagePicker"
import router from "../routing/router"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import genders from "../data/genders"
import {
  useUpsertUserProfileMutation,
  useGetUserQuery,
} from "../redux/users/userApi"
import useImageStorage from "../hooks/useImageStorage"
import { UserProfileRecord } from "../redux/users/user"
import { useSelector } from "react-redux"
import { selectAuthUser, selectHasProfile } from "../redux/app/appSelectors"
import { logOut } from "../lib/auth"

const ProfileCreationPage: React.FC = () => {
  const loggedInUser = useSelector(selectAuthUser)
  const authUser = loggedInUser!
  const hasProfile = useSelector(selectHasProfile)
  const { data: userData, isLoading } = useGetUserQuery(authUser)
  const [upsertUserProfile] = useUpsertUserProfileMutation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [displayName, setDisplayName] = useState<string>("")
  const [selectedPronoun, setSelectedPronoun] = useState("")
  const [selectedCuisines, setSelectedCuisines] = useState<string[]>([])
  const [selectedBirthday, setSelectedBirthday] = useState<Date | null>(null)
  const [favoriteFoodLocations, setFavoriteFoodLocations] = useState<string>("")
  const [photoURL, setPhotoURL] = useState<string | null>(null)
  const [photoFile, setPhotoFile] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const { uploadImage, uploading, error } = useImageStorage()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading) {
      setPhotoURL(authUser.photoURL)
      setDisplayName(authUser.displayName)

      if (userData != null) {
        setSelectedPronoun(userData.pronoun || "")
        setSelectedCuisines(userData.cuisines || [])
        setSelectedBirthday(
          userData.birthdate ? new Date(userData.birthdate) : null,
        )
        setFavoriteFoodLocations(userData.favoriteFoodLocations || "")

        if (userData.photoURL != null) {
          setPhotoURL(userData.photoURL)
        }

        if (userData.displayName != null) {
          setDisplayName(userData.displayName)
        }
      }
    }
  }, [authUser.displayName, authUser.photoURL, isLoading, userData])

  const handlePronounCheckBoxChange = (pronoun: string) => {
    setSelectedPronoun(pronoun)
  }

  const handleFavoriteFoodLocationsChange = (value: string) => {
    if (value.length <= 150) {
      setFavoriteFoodLocations(value)
    }
  }

  const handleDisplayNameChange = (value: string) => {
    if (value.length <= 30) {
      setDisplayName(value)
    }
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value) {
      setSelectedBirthday(new Date(value))
    } else {
      setSelectedBirthday(null)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
      setPhotoFile(file)
      setImagePreview(reader.result as string)
    }
    reader.readAsDataURL(file)
  }

  const handleSave = async () => {
    if (
      !selectedBirthday ||
      !selectedPronoun ||
      !favoriteFoodLocations ||
      !authUser
    ) {
      return
    }

    setIsSaving(true)
    try {
      let newPhotoUrl = photoURL
      if (photoFile != null) {
        newPhotoUrl = await uploadImage(photoFile)
      }

      const profile: UserProfileRecord = {
        displayName: displayName,
        pronoun: selectedPronoun,
        cuisines: [],
        birthdate: selectedBirthday ? selectedBirthday.toISOString() : null,
        favoriteFoodLocations: favoriteFoodLocations,
        photoURL: newPhotoUrl,
      }

      await upsertUserProfile({ authUser, profile })
    } finally {
      setIsSaving(false)
    }

    router.navigate("/main/request")
  }

  const handleCancel = () => {
    if (!hasProfile) {
      logOut()
    } else {
      navigate(-1)
    }
  }

  return (
    <MainLayout isLanding={true}>
      <Box sx={{ alignItems: "center", overflowY: "auto", paddingX: "40px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            <b>Choose a picture for your profile.&nbsp;</b>
          </Typography>
          <br />
          <ImagePicker
            imagePreview={imagePreview}
            uploading={uploading}
            imageUrl={photoURL}
            error={error}
            handleFileChange={handleFileChange}
          />
        </Box>
        <br />
        <Box>
          <Typography variant="h6">
            <b>Please enter your name.&nbsp;</b>
            {!displayName && (
              <b style={{ color: theme.palette.error.main }}>*</b>
            )}
          </Typography>
          <Typography>This name will be displayed to other users.</Typography>
        </Box>
        <Box>
          <Box>
            <TextField
              variant="outlined"
              value={displayName}
              onChange={(e) => handleDisplayNameChange(e.target.value)}
              inputProps={{
                maxLength: 30,
              }}
              sx={{
                backgroundColor: "white",
                minWidth: "240px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #DDD",
                marginTop: "20px",
              }}
            />
          </Box>
        </Box>
        <br />
        <Box>
          <br />
          <Typography variant="h6">
            <b>When is your birthday?&nbsp;</b>
            {!selectedBirthday && (
              <b style={{ color: theme.palette.error.main }}>*</b>
            )}
          </Typography>
          <Typography>Your age will be visible on your profile</Typography>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={selectedBirthday}
              onChange={(newValue: Date | null) =>
                setSelectedBirthday(newValue)
              }
              slotProps={{
                textField: {
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "5px",
                    border: "1px solid #DDD",
                    marginTop: "20px",
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ paddingTop: "40px" }}>
          <Box>
            <Typography variant="h6">
              <b>What is your preferred pronoun?&nbsp;</b>
              {!selectedPronoun && (
                <b style={{ color: theme.palette.error.main }}>*</b>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-around",
              marginTop: "20px",
              flexWrap: "wrap",
            }}
          >
            {genders.map((gender, index) => (
              <Box key={index} sx={{ display: "flex", flexWrap: "wrap" }}>
                <CheckBox
                  text={gender}
                  checked={selectedPronoun === gender}
                  onChange={() => handlePronounCheckBoxChange(gender)}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ paddingTop: "40px" }}>
          <Box>
            <Typography variant="h6">
              <b>What are some of your favourite lunch spots?&nbsp;</b>
              {!favoriteFoodLocations && (
                <b style={{ color: theme.palette.error.main }}>*</b>
              )}
            </Typography>
            <Typography>
              This could be your local coffee shop, restaurant, or even a bench
              in the park. This will be visible on your profile
            </Typography>
          </Box>
          <TextField
            variant="outlined"
            placeholder="Freshii is always a no fail option. I also really like Calibowls."
            multiline
            value={favoriteFoodLocations}
            onChange={(e) => handleFavoriteFoodLocationsChange(e.target.value)}
            sx={{
              backgroundColor: "white",
              minWidth: "240px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #DDD",
              marginTop: "20px",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingY: "40px",
          }}
        >
          <Button variant="contained" color="error" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              !selectedBirthday ||
              !selectedPronoun ||
              !favoriteFoodLocations ||
              isSaving
            }
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
        </Box>
      </Box>
    </MainLayout>
  )
}

export default ProfileCreationPage
