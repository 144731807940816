import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import store, { persistor } from "./redux/store" // Import your store
import { PersistGate } from "redux-persist/integration/react"
import { initFirebase } from "./firebase"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./theme"
import App from "./App"

const rootElement = document.getElementById("root") as HTMLElement
const root = createRoot(rootElement)

initFirebase()

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
)
