import { collection, query, onSnapshot } from "firebase/firestore"
import { db } from "../../firebase"
import { makeRequestRef, resetRequestApi } from "./requestApi"
import { setHasRequest } from "../app/appSlice"
import store from "../store"

export const setupRequestSubscription = () => {
  const requestsQuery = query(collection(db, "requests"))

  const unsubscribe = onSnapshot(requestsQuery, (querySnapshot) => {
    resetRequestApi()
  })

  return unsubscribe
}

export const setupUserRequestSubscription = (userUid: string) => {
  const requestRef = makeRequestRef(userUid)

  const unsubscribe = onSnapshot(requestRef, (requestSnapshot) => {
    resetRequestApi()

    const hasRequiest = requestSnapshot.exists()
    store.dispatch(setHasRequest(hasRequiest))
  })

  return unsubscribe
}
