import React from "react"
import { Box, Card, CardMedia, Input, Button } from "@mui/material"

interface ImagePickerProps {
  imagePreview: string | null
  uploading: boolean
  imageUrl: string | null
  error: string | null
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  imagePreview,
  uploading,
  imageUrl,
  error,
  handleFileChange,
}) => {
  return (
    <Box
      sx={{
        marginBottom: "20px",
        width: "260px",
        height: "auto",
      }}
    >
      {imagePreview && (
        <Card
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={imagePreview}
            sx={{
              width: "20vh",
              height: "20vh",
              borderRadius: "50%",
              border: "5px solid white",
              objectFit: "cover",
            }}
          />
        </Card>
      )}
      {imageUrl && imagePreview == null && (
        <Card
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={imageUrl}
            sx={{
              width: "20vh",
              height: "20vh",
              borderRadius: "50%",
              border: "5px solid white",
              objectFit: "cover",
            }}
          />
        </Card>
      )}
      {uploading && <p>Uploading...</p>}
      {error && <p>Error: {error}</p>}
      <label htmlFor="choose-photo">
        <Input
          style={{ display: "none" }}
          id="choose-photo"
          name="choose-photo"
          type="file"
          onChange={handleFileChange}
          inputProps={{ accept: "image/*" }}
        />
        <br />
        <Button color="error" variant="contained" component="span">
          Choose Photo
        </Button>
      </label>
    </Box>
  )
}

export default ImagePicker
