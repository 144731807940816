import { RouterProvider } from "react-router-dom"
import router from "./routing/router"
import useFirebaseAuth from "./hooks/useFirebaseAuth"
import useDbSubscribe from "./hooks/useDbSubscribe"

function App() {
  const { isLoading } = useFirebaseAuth()
  useDbSubscribe()

  if (isLoading) {
    return (
      <div
        style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}
      ></div>
    )
  }

  return <RouterProvider router={router} />
}

export default App
