import { doc, getDoc } from "firebase/firestore"
import { db } from "../../firebase"
import {
  LunchRequest,
  LunchRequestRecord,
  convertLunchRequestRecordToLunchRequest,
} from "./request"
import { UserRecord, userDataFromRecord } from "../users/user"

export interface GetRequestParams {
  uid: string
}

export const getRequest = async ({
  uid,
}: GetRequestParams): Promise<LunchRequest | undefined> => {
  const docRef = doc(db, "requests", uid)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    const lunchRequestRecord = docSnap.data() as LunchRequestRecord

    const userDoc = await getDoc(lunchRequestRecord.userRef)
    const userRecord = userDoc.data() as UserRecord
    const userData = userDataFromRecord(userRecord)

    const lunchRequest = convertLunchRequestRecordToLunchRequest(
      lunchRequestRecord,
      uid,
      userData,
    )

    return lunchRequest
  } else {
    return undefined
  }
}
