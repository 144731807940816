const halifaxStreets = [
  "Downtown - Halifax",
  "North End - Halifax",
  "South End - Halifax",
  "West End - Halifax",
  "Clayton Park - Halifax",
  "Bayer's Lake - Halifax",
  "Downtown - Dartmouth",
  "Dartmouth Crossing",
  "MicMac Mall/Crichton Park - Dartmouth",
  "Millview - Bedford",
  "Glen Moir/Oakmont - Bedford",
]

export default halifaxStreets
