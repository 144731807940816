import { Box, Typography } from "@mui/material"

export const handleHours = (hours: string[]) => {
  return hours.map((hour, index) => (
    <Box
      key={index}
      sx={{
        padding: "10px",
        backgroundColor: "#FFC000",
        borderRadius: "20px",
        color: "black",
        width: "fit-content",
        marginRight: 2,
        marginBottom: 2,
      }}
    >
      <Typography fontFamily={"sans-serif"}>{hour}</Typography>
    </Box>
  ))
}
