import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { HomeLayout } from "./HomeLayout"
import ProtectedProfileLayout from "./ProtectedLayout"
import LoginPage from "../pages/LoginPage"
import WelcomePage from "../pages/WelcomePage"
import RequestPage from "../pages/RequestPage"
import ConnectPage from "../pages/ConnectPage"
import ProfilePage from "../pages/ProfilePage"
import NotificationsPage from "../pages/NotificationsPage"
import MessagesPage from "../pages/MessagesPage"
import ProfileCreationPage from "../pages/ProfileCreationPage"
import ComingSoonPage from "../pages/ComingSoonPage"
import ProtectedConnectLayout from "./ProtectedConnectLayout"

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/coming-soon" element={<ComingSoonPage />} />

      <Route element={<HomeLayout />}>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route path="/profile" element={<ProtectedProfileLayout />}>
        <Route path="view" element={<ProfilePage />} />
        <Route path="create" element={<ProfileCreationPage />} />
      </Route>

      <Route path="/main" element={<ProtectedConnectLayout />}>
        <Route path="request" element={<RequestPage />} />
        <Route path="connect" element={<ConnectPage />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="messages/:fromUserUid" element={<MessagesPage />} />
      </Route>
    </>,
  ),
)

export default router
