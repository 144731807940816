import React from "react"
import { Box, Typography, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDeleteNotificationMutation } from "../redux/notifications/notificationApi"
import { useSelector } from "react-redux"
import { selectAuthUser } from "../redux/app/appSelectors"

type MessageSenderCardProps = {
  senderId: string
  fullName: string
  image: string
  lastMessage?: string
  onClick: (senderId: string) => void
}

const MessageSenderCard: React.FC<MessageSenderCardProps> = (props) => {
  return (
    <Box
      onClick={() => props.onClick(props.senderId)}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginY: 2,
        }}
      >
        <Box
          component="img"
          sx={{
            width: 50,
            height: 50,
            borderRadius: "50%",
            marginRight: 2,
          }}
          alt="Avatar"
          src={props.image}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            borderRadius: "6px",
            color: "black",
          }}
        >
          <Typography variant="body1">
            <b>{props.fullName}</b>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "left" }}>
            {props.lastMessage && props.lastMessage.length > 40
              ? `${props.lastMessage.substring(0, 40)}...`
              : props.lastMessage}
          </Typography>
        </Box>
      </Box>
      <DeleteNotificationButton notificationId={props.senderId} />
    </Box>
  )
}

export default MessageSenderCard

interface DeleteNotificationButtonProps {
  notificationId: string
}
const DeleteNotificationButton = ({
  notificationId,
}: DeleteNotificationButtonProps) => {
  const authUser = useSelector(selectAuthUser)

  const [deleteNotification, { isLoading, isSuccess, isError }] =
    useDeleteNotificationMutation()

  const handleDelete = async () => {
    if (authUser != null) {
      try {
        await deleteNotification({ authUser, notificationId })
      } catch (error) {
        console.error("Error deleting notification:", error)
      }
    }
  }

  return (
    <>
      <IconButton
        onClick={handleDelete}
        disabled={isLoading}
        disableFocusRipple={false}
      >
        <DeleteIcon />
      </IconButton>
      {/* {isLoading
        ? "Deleting..."
        : isSuccess
        ? "Deleted"
        : isError
        ? "Failed to Delete"
        : "Delete Notification"} */}
    </>
  )
}
