import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material"

interface BlockUserDialogProps {
  open: boolean
  onClose: () => void
}

const BlockUserDialog: React.FC<BlockUserDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="profile-delete-dialog-title"
    >
      <DialogTitle id="profile-delete-dialog-title">
        {"Are you sure you want to block this user?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This action is permanent.</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          onClick={onClose}
          autoFocus
          variant="text"
          sx={{ fontSize: "20px" }}
        >
          Cancel
        </Button>
        <Button autoFocus onClick={onClose} variant="outlined" color="error">
          Block
        </Button>
        <Button autoFocus onClick={onClose} variant="outlined" color="error">
          Report & Block
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BlockUserDialog
