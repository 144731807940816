import { DocumentReference } from "firebase/firestore"
import { makeUserRef } from "../users/userApi"
import { UserData } from "../users/user"

export interface LunchRequestData {
  location: string
  date: string | null
  hours: string[]
  cuisines: string[]
  specialRequests: string[]
}

export interface LunchRequestBaseData extends LunchRequestData {
  uid: string
  userUid: string
}

export interface LunchRequest extends LunchRequestBaseData {
  userData: UserData
}

export interface LunchRequestRecord extends LunchRequestData {
  uid: string
  userRef: DocumentReference
}

export const convertLunchRequestRecordToLunchRequest = (
  record: LunchRequestRecord,
  uid: string,
  userData: UserData,
): LunchRequest => {
  const { userRef, ...rest } = record
  return {
    ...rest,
    uid,
    userUid: userRef.id,
    userData,
  }
}

export const convertLunchRequestDataToLunchRequestRecord = (
  uid: string,
  request: LunchRequestData,
): LunchRequestRecord => {
  return {
    ...request,
    uid: uid,
    userRef: makeUserRef(uid),
  }
}

export const convertLunchRequestRecordToLunchRequestBase = (
  record: LunchRequestRecord,
  uid: string,
): LunchRequestBaseData => {
  const { userRef, ...rest } = record
  return {
    ...rest,
    uid,
    userUid: userRef.id,
  }
}
