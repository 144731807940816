import { Timestamp } from "firebase/firestore"
import { UserData } from "../users/user"

export interface Notification {
  fromUserUid: string
  message: string
  timestamp: number
  fromUserData: UserData
}

export interface NotificationRecord {
  message: string
  timestamp: Timestamp
}

export const convertNotificationRecordtoNotification = (
  notificationRecordId: string,
  notificationRecord: NotificationRecord,
  fromUserData: UserData,
): Notification => {
  const message = notificationRecord.message
  const timestamp = notificationRecord.timestamp.toMillis()
  const fromUserUid = notificationRecordId

  return {
    fromUserUid,
    message,
    timestamp,
    fromUserData,
  }
}
