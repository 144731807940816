import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth"
import { persistor } from "../redux/store"
import { auth } from "../firebase"

export enum LoginResult {
  OK,
  ERR_POPUP_BLOCKED,
  ERR_OTHER,
}

export const loginWithGoogle = async (): Promise<LoginResult> => {
  await logOut()

  const provider = new GoogleAuthProvider()

  try {
    // This will trigger a call to our onAuthStateChanged()
    await signInWithPopup(auth, provider)
    return LoginResult.OK
  } catch (err: any) {
    console.error(err.code)
    if (err.code === "auth/popup-blocked") {
      return LoginResult.ERR_POPUP_BLOCKED
    } else if (
      err.code === "auth/cancelled-popup-request" ||
      err.code === "auth/popup-closed-by-user"
    ) {
      return LoginResult.OK
    } else {
      return LoginResult.ERR_OTHER
    }
  }
}

export const logOut = async () => {
  // This will trigger a call to our onAuthStateChanged()
  persistor.purge()
  return signOut(auth)
}
