const popularCuisines = [
  "Open to Any Cuisine",
  "Italian",
  "Mexican",
  "Indian",
  "Japanese",
  "Chinese",
  "French",
  "Thai",
  "Greek",
  "Spanish",
  "Mediterranean",
  "Middle Eastern",
  "American",
  "Brazilian",
  "Korean",
  "Vietnamese",
  "Cuban",
  "Moroccan",
  "Eastern European",
  "Jamaican",
  "African",
]

export default popularCuisines
