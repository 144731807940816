import { Box } from "@mui/material"
import MainMenu from "../components/MainMenu"
import NotificationsBadge from "../components/NotificationsBadge"
import { useSelector } from "react-redux"
import { selectHasRequest, selectIsLoggedIn } from "../redux/app/appSelectors"

const Navbar = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const hasRequest = useSelector(selectHasRequest)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: "100%",
        paddingTop: 2,
      }}
    >
      <MainMenu />
      {isLoggedIn && hasRequest && <NotificationsBadge />}
    </Box>
  )
}

export default Navbar
