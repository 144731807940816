import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { db } from "../firebase"

interface Account {
  email: string
  type: string
}

interface UpsertAccountArg {
  uid: string
  account: Account | undefined
}

export const accountApiSlice = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["Account"],
  endpoints: (builder) => ({
    upsertAccount: builder.mutation<Account | undefined, UpsertAccountArg>({
      queryFn: async ({ uid, account }) => {
        const docRef = doc(collection(db, "accounts"), uid)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          await updateDoc(docRef, account as { [x: string]: any })
        } else {
          await setDoc(docRef, account)
        }

        return { data: account }
      },
      invalidatesTags: ["Account"],
    }),
  }),
})

export const { useUpsertAccountMutation } = accountApiSlice
