import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthUser, UserSettings } from "../users/user"

interface AppState {
  user: AuthUser | null
  userSettings: UserSettings | null
  hasProfile: boolean
  hasRequest: boolean
  hasUnreadNotifications: boolean
  messagingWithUid: string | null
}

const initialState: AppState = {
  user: null,
  userSettings: null,
  hasProfile: false,
  hasRequest: false,
  hasUnreadNotifications: false,
  messagingWithUid: null,
}

const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setUserSettings: (state, action: PayloadAction<UserSettings | null>) => {
      state.userSettings = action.payload
    },
    setHasProfile: (state, action: PayloadAction<boolean>) => {
      state.hasProfile = action.payload
    },
    setHasRequest: (state, action: PayloadAction<boolean>) => {
      state.hasRequest = action.payload
    },
    setNotificationFromUid: (state, action: PayloadAction<string | null>) => {
      const fromUid = action.payload
      if (state.messagingWithUid !== fromUid) {
        state.hasUnreadNotifications = true
      }
    },
    setMessagingWithUid: (state, action: PayloadAction<string | null>) => {
      state.messagingWithUid = action.payload
    },
    setUser: (state, action: PayloadAction<AuthUser | null>) => {
      state.user = action.payload
    },
    clearUnreadNotifications: (state, action: PayloadAction<void>) => {
      state.hasUnreadNotifications = false
    },
    clearUser: (state, action: PayloadAction<void>) => {
      state.user = null
      state.hasProfile = false
      state.hasRequest = false
      state.userSettings = null
    },
  },
})

export const {
  setUserSettings,
  setHasRequest,
  setHasProfile,
  setNotificationFromUid,
  clearUnreadNotifications,
  setMessagingWithUid,
  setUser,
  clearUser,
} = appSlice.actions

export default appSlice.reducer
