import { RootState } from "../store"

export const selectAuthUser = (state: RootState) => state.app.user

export const selectHasProfile = (state: RootState) => state.app.hasProfile

export const selectHasRequest = (state: RootState) => state.app.hasRequest

export const selectAllowRequests = (state: RootState) =>
  state.app.userSettings?.allowRequests

export const selectIsLoggedIn = (state: RootState) => state.app.user != null

export const selectUserId = (state: RootState) => state.app.user?.uid

export const selectHasUnreadNotifications = (state: RootState) =>
  state.app.hasUnreadNotifications
