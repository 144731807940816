export interface UserData {
  uid: string
  displayName: string
  pronoun?: string
  birthdate?: string
  age?: string
  photoURL?: string
  cuisines?: string[]
  favoriteFoodLocations?: string
}

export interface AuthUser {
  uid: string
  displayName: string
  photoURL: string | null
}

export interface UserProfileRecord {
  displayName: string
  birthdate: string | null
  cuisines: string[] | null
  photoURL: string | null
  favoriteFoodLocations: string | null
  pronoun: string | null
}

export interface UserRecord {
  authUser: AuthUser
  profile: UserProfileRecord
}

export interface UserSettings {
  allowRequests: boolean
}

export const userDataFromRecord = (userRecord: UserRecord): UserData => {
  let displayName = userRecord.authUser.displayName
  const uid = userRecord.authUser.uid

  let pronoun, age, favoriteFoodLocations, cuisines, birthdate, photoURL

  if (userRecord.authUser.photoURL != null) {
    photoURL = userRecord.authUser.photoURL
  }

  const profile = userRecord.profile
  const hasProfile = profile != null

  if (hasProfile) {
    if (profile.displayName != null) {
      displayName = profile.displayName
    }

    if (profile.pronoun != null) {
      pronoun = profile.pronoun
    }

    if (profile.favoriteFoodLocations != null) {
      favoriteFoodLocations = profile.favoriteFoodLocations
    }

    if (profile.cuisines != null) {
      cuisines = profile.cuisines
    }

    if (profile.birthdate != null) {
      birthdate = profile.birthdate

      const birthdateDate = new Date(birthdate)
      const ageDate = new Date(Date.now() - birthdateDate.getTime())
      age = Math.abs(ageDate.getUTCFullYear() - 1970).toString()
    }

    if (profile.photoURL != null) {
      photoURL = profile.photoURL
    }
  }

  return {
    uid,
    displayName,
    photoURL,
    pronoun,
    birthdate,
    age,
    favoriteFoodLocations,
    cuisines,
  }
}
