import { useEffect, useState } from "react"
import useAuth from "./useAuth"
import { setupNotificationSubscription } from "../redux/notifications/subscribe"
import { setupUserSubscription } from "../redux/users/subscribe"
import { setupUserRequestSubscription } from "../redux/requests/subscribe"

const useDbSubscribe = () => {
  const { user } = useAuth()
  const [unsubscribes, setUnsubscribes] = useState<(() => void)[]>([])

  useEffect(() => {
    // Clean up previous subscriptions
    unsubscribes.forEach((unsubscribe) => unsubscribe())
    setUnsubscribes([])

    if (user && user.uid) {
      const notificationUnsubscribe = setupNotificationSubscription(user.uid)
      const userUnsubscribe = setupUserSubscription(user.uid)
      const userRequestUnsubscribe = setupUserRequestSubscription(user.uid)
      setUnsubscribes([
        notificationUnsubscribe,
        userUnsubscribe,
        userRequestUnsubscribe,
      ])
    }

    // Cleanup function to unsubscribe when the component unmounts or user changes
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe())
      setUnsubscribes([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
}

export default useDbSubscribe
